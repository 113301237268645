<template>
  <div class="cart">
    <p v-show="!products.length">Your cart is empty.</p>
    <table class="table is-striped" v-if="products.length && this.$store.getters.allProducts.length">
      <thead>
        <tr>
          <th>Name</th>
          <th>Quantity</th>
          <th>Price/unit</th>
        </tr>
      </thead>
      <tbody>
        <tr class="product-divider" v-for="p in products" :key="p.id">
          <td @click="loadItem(p)">
            <h4>{{ p.name }}</h4>
            <ul>
              <span :key="modifier" v-for="modifier in p.modifiers">
                <li :key="modifier + '-' + m" v-for="m in modifier">
                  {{ m.name }}
                </li>
              </span>
            </ul>
          </td>
          <td>
            <p>
              <b class="cart-symbol" @click="changeLineItemQuantityWrapper(p.hash, -1)"><font-awesome-icon class="quantity-icon" icon="minus-circle" /></b>
              <span class="quantity">{{ p.quantity }}</span>
              <b class="cart-symbol" @click="changeLineItemQuantityWrapper(p.hash, 1)"><font-awesome-icon class="quantity-icon" icon="plus-circle" /></b>
            </p>
          </td>
          <td>${{ p.price.toFixed(2) }}</td>
        </tr>
        <tr v-if="siteSettings.surcharge">
          <td class="payment"><b>Holiday Surcharge:</b></td>
          <td>{{ 100 * surcharge.percentage }}%</td>
          <td>{{ surchargeTotal.toFixed(2) }}</td>
        </tr>
        <tr>
          <td><b>Total:</b></td>
          <td></td>
          <td>
            <b>${{ total.toFixed(2) }}</b>
          </td>
        </tr>
        <tr class="product-divider payment" v-for="(payment, idx) in payments" :key="payment + '-' + idx">
          <td class="payment">{{ payment.type }}</td>
          <td class="trash">
            <font-awesome-icon icon="fa-solid fa-trash" class="bin-icon" @click="deletePayment(idx, payment)" />
          </td>
          <td>${{ payment.value.toFixed(2) }}</td>
        </tr>

        <tr>
          <td><b>Paid:</b></td>
          <td></td>
          <td>
            <b>${{ paid.toFixed(2) }}</b>
          </td>
        </tr>

        <tr>
          <td><b>Balance:</b></td>
          <td></td>
          <td>
            <b>${{ remaining.toFixed(2) }}</b>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="$parent.$options.name !== 'CheckoutModal'" class="cart-buttons">
      <button v-if="!hideCheckoutButton && products.length" class="clear-button" @click="confirmModal(false)">
        <font-awesome-icon icon="fa-solid fa-trash" class="bin-icon" /></button
      ><button v-if="!hideCheckoutButton && products.length" class="primary checkout-button" @click="showCheckoutModal">Checkout</button>
    </div>
    <div>
      <button v-if="Object.keys(getWebOrder).length != 0" @click.stop.prevent="$emit('showWebOrderModal', true)" class="previous-order">Order Status</button>
    </div>
  </div>
  <Confirm :open="openConfirm" :message="'Are you sure you want to delete your cart'" @confirmModal="confirmModal" />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Confirm from "./Confirm.vue";

export default {
  data() {
    return {
      checkoutProceed: true,
      surcharge: {
        percentage: 0.15,
        total: 0,
      },
      openConfirm: false,
    };
  },
  components: {
    Confirm,
  },
  props: {
    hideCheckoutButton: { type: Boolean, default: false },
    allowSplit: { type: Boolean, default: false },
  },
  emits: ["loadOrderLineItem", "showCheckoutModal", "showTab", "clearPayments"],
  methods: {
    checkMods() {
      let mods = [];
      this.checkoutProceed = true;
      // console.log(this.products);
      this.products.forEach((prod) => {
        Object.keys(prod.modifiers).forEach((modifierset) => {
          let foundModifierSet = this.modifier_sets.find((m) => {
            return m.value._id === modifierset;
          });

          if (foundModifierSet && foundModifierSet.value.rules) {
            if (foundModifierSet.value.rules.inventory) {
              /* send to web socket */
              prod.modifiers[modifierset].forEach((mod) => {
                this.channel
                  .push("modifier:get:quantity", {
                    modifierset: modifierset,
                    modifier: mod,
                  })
                  .receive("ok", (reply) => {
                    mods.push(reply);
                    this.checkoutProceed = false;
                  });
              });
              let proceed = mods;
              console.log(
                this.checkoutProceed,
                mods,
                proceed.map((element) => element)
              );
            }
          }
        });
      });
      if (this.checkoutProceed) {
        this.showCheckoutModal();
      } else {
        this.$notify({
          text: "Products currently unavailable, please choose again.",
          type: "error",
          duration: 1500,
        });
      }
    },
    deletePayment(idx, payment) {
      if (payment.type.includes("account") || payment.type.includes("voucher")) {
        const currentAccount = {
          ID: payment.id,
          name: payment.name,
          value: payment.value,
          doc_type: "credit",
          date: new Date().toISOString(),
        };
        this.channel.push(`${payment.type}:doc`, currentAccount);
      }
      this.$store.dispatch("deletePayment", idx);
    },
    changeLineItemQuantityWrapper(productHash, delta) {
      if (delta) {
        let currentProduct = this.products.find((lineItem) => lineItem.hash === productHash);
        this.changeCartDelta(currentProduct, delta);

        this.changeLineItemQuantity({ productHash: productHash, delta: delta });
      }
      if (!this.products.length) {
        this.$store.dispatch("clearPayments");
      }
    },
    confirmModal(action) {
      if (action) {
        this.$store.dispatch("clearCart");
        this.$notify({
          text: "Cart Cleared",
          type: "success",
          duration: 1500,
        });
      }
      this.openConfirm = !this.openConfirm;
    },
    changeCartDelta(currentProduct, delta) {
      Object.keys(currentProduct.modifiers).forEach((modifierset) => {
        let foundModifierSet = this.modifier_sets.find((m) => {
          return m.value._id === modifierset;
        });

        if (foundModifierSet && foundModifierSet.value.rules) {
          if (foundModifierSet.value.rules.inventory) {
            /* send to web socket */
            currentProduct.modifiers[modifierset].forEach((modifierDeltaChange) => {
              this.channel.push("modifier:set:delta", {
                modifierset: modifierset,
                modifier: modifierDeltaChange,
                delta: delta * -1, // the modifiers available is the opposite of line item quantity
              });
            });
          }
        }
      });
    },
    ...mapActions(["changeLineItemQuantity"]),
    async showCheckoutModal() {
      //await this.$store.commit('setStripeTotalMutation', this.total * 100);
      this.$emit("showCheckoutModal", true);
    },
    loadItem(lineItem) {
      console.log("loadItem", lineItem);
      this.$emit("loadOrderLineItem", lineItem);
    },
  },
  computed: {
    ...mapGetters({
      getClosed: "getClosed",
      products: "cartProducts",
      allProducts: "allProducts",
      payments: "cartPayments",
      modifier_sets: "modifier_sets",
      siteSettings: "siteSettings",
      getWebOrder: "getWebOrder",
    }),
    surchargeTotal() {
      const total = this.products.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
      return this.surcharge.percentage * total;
    },
    ccTotal() {
      const total = this.products.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
      return 0.03 * total;
    },
    total() {
      let total = this.products.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
      if (this.siteSettings.surcharge) {
        total += this.surchargeTotal;
      }
      return total;
    },
    paid() {
      return this.payments.reduce((total, p) => {
        return total + p.value;
      }, 0);
    },
    remaining() {
      return this.total - this.paid;
    },
  },
  watch: {
    products() {
      let companion = { products: this.products };
      if (this.siteSettings.surcharge) {
        companion["surcharge"] = true;
        companion["surchargeTotal"] = this.surchargeTotal;
      }
      this.channel.push("cart:state", companion);
    },
  },
};
</script>
<style scoped lang="scss">
.cart {
  position: relative;
  border-radius: 1rem 0rem 0rem 0rem;
  .cart-title {
    margin: 0;
  }
  div {
    .previous-order {
      margin: 0;
      border: 0;
    }
  }
}
.cart-symbol {
  padding: 0rem;
  //1rem 0rem 1rem;
  font-size: x-large;
  color: var(--warning-colour);
}

.cart_symbol:hover {
  cursor: pointer;
}

table {
  width: 100%;
  tr th {
    font-weight: 700;
  }

  td {
    text-align: center;
    vertical-align: top;
    padding-top: 1rem;
    p {
      margin: 0px;
      line-height: 1rem;
    }
  }
  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
    // width: 250px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 80px;

    .quantity-icon {
      color: var(--action-colour);
    }

    span.quantity {
      margin: 0px 10px 0px 5px;
      display: inline-block;
      width: 5px;
      vertical-align: 4px;
    }
  }
  th:nth-child(3),
  td:nth-child(3) {
    text-align: right;
    width: 100px;
  }

  .product-divider.payment {
    text-transform: capitalize;
  }
  .product-divider td {
    border-bottom: 1px solid #555;
  }
  .trash {
    color: var(--action-colour);
  }
}

h2 {
  margin-top: 0px;
  padding-top: 1rem;
  span:nth-of-type(2) {
    color: #aaa;
  }
  cursor: pointer;
}

h4 {
  margin: 0px;
}

ul {
  display: block !important;
  list-style: none;
  margin: 0px;
  margin-top: 1rem;
  padding: 0px;

  li {
    margin-bottom: 0.5rem;
  }
}

.cart-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  margin: 0rem 0px 1rem 0px;

  .clear-button {
    padding: 1rem;
    width: 5rem;
    font-size: 1.5rem;
    border: 3px solid var(--action-colour);
    color: var(--action-colour);
    background-color: var(--secondary-colour);
    margin: 1rem 0px 0px 0px;
  }

  .checkout-button {
    padding: 1rem;
    width: 16rem;
    font-size: 1.5rem;
    border: 0px;
    color: var(--primary-colour);
    margin: 1rem 0px 0px 0px;
  }
}

.recent {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
}

@media only screen and (orientation: portrait) {
  .cart {
    border-radius: 0rem;
  }
  .cart-buttons {
    .clear-button {
      width: 25%;
    }

    .checkout-button {
      width: 75%;
    }
  }
}
</style>
